import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Lenis from "lenis";
import {
  BullhornPage1,
  HubspotForFinance,
  HubspotForLegal,
  HubspotForManufacturing,
  HubspotForRetail,
  HubspotAppInstructions,
  PrivacyAndTerms,
  Home,
} from "./pages";

import { ThemeProvider } from "styled-components";
import theme from "./theme";
import qs from "qs";

import TagManager from "react-gtm-module";
import { gtmId } from "./utils/config";
import { AppContext } from "./context";
import ProductHuntLogo from "./components/ProductHuntLogo";

!!gtmId && TagManager.initialize({ gtmId });

const App = () => {
  const lenis = new Lenis();

  const [resetToken, setResetToken] = useState(undefined);
  const [extensionId, setExtensionId] = useState(undefined);
  const [isQaEnv, setIsQaEnv] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(undefined);
  const [isUserNotCreateFirstCampaign, setIsUserNotCreateFirstCampaign] =
    useState(undefined);
  const [isReadyToLaunchCampaign, setIsReadyToLaunchCampaign] =
    useState(undefined);
  const [isWarnHighBounceRateCampaign, setIsWarnHighBounceRateCampaign] =
    useState(undefined);
  const [campaignId, setCampaignId] = useState(undefined);

  useEffect(() => {
    const urlParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (urlParams.linkClickId) {
      localStorage.setItem("linkClickId", urlParams.linkClickId);
    }
    if (urlParams.rt) {
      setResetToken(urlParams.rt);
    }
    if (urlParams.cc) {
      setConfirmationCode(urlParams.cc);
    }
    if (urlParams.isUserNotCreateFirstCampaign) {
      setIsUserNotCreateFirstCampaign(
        Boolean(urlParams.isUserNotCreateFirstCampaign),
      );
    }
    if (urlParams.isReadyToLaunchCampaign) {
      setIsReadyToLaunchCampaign(Boolean(urlParams.isReadyToLaunchCampaign));
    }
    if (urlParams.isWarnHighBounceRateCampaign) {
      setIsWarnHighBounceRateCampaign(
        Boolean(urlParams.isWarnHighBounceRateCampaign),
      );
    }
    if (urlParams.campaignId) {
      setCampaignId(urlParams.campaignId);
    }
    if (urlParams.env && urlParams.env === "qa") {
      setIsQaEnv(true);
    }

    const reachExtensionId = window.reachExtensionId || urlParams.extensionId;
    if (reachExtensionId) {
      setExtensionId(reachExtensionId);
    }
    window.omEvents = window.omEvents || [];

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  return (
    <AppContext.Provider
      value={{
        resetToken,
        extensionId,
        confirmationCode,
        isUserNotCreateFirstCampaign,
        isReadyToLaunchCampaign,
        isWarnHighBounceRateCampaign,
        campaignId,
        isQaEnv,
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bh1" element={<BullhornPage1 />} />
            <Route path="/hubspot-setup" element={<HubspotAppInstructions />} />
            <Route
              path="/hubspot-for-manufacturing"
              element={<HubspotForManufacturing />}
            />
            <Route path="/hubspot-for-retail" element={<HubspotForRetail />} />
            <Route
              path="/hubspot-for-finance"
              element={<HubspotForFinance />}
            />
            <Route path="/hubspot-for-legal" element={<HubspotForLegal />} />
            <Route path="/privacy" element={<PrivacyAndTerms isPrivacy />} />
            <Route
              path="/terms-conditions"
              element={<PrivacyAndTerms isTermsOfUse />}
            />
          </Routes>
        </Router>
        <ProductHuntLogo />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
